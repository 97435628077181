<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h6 font-weight-regular"> Carico carro {{ targaCarro }} </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12">
        <v-expansion-panels v-model="infoPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
              Dati viaggio
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TripInfoPanelFull :viaggio="viaggioOut" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tabs">
          <v-tab>Pianificate</v-tab>
          <v-tab>Yard</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" touchless>
          <v-tab-item>
            <v-container style="background-color: #ffffff">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field v-model="searchPianificate" append-icon="mdi-magnify" label="Cerca" single-line hide-details filled dense style="max-width: 800px" />
                </v-col>
              </v-row>
            </v-container>

            <v-container style="background-color: #ffffff" fluid>
              <v-row align="center">
                <v-col cols="12">
                  <v-data-table
                    :headers="visiteUtiHeaders"
                    :items="visiteUtiPianificate"
                    :items-per-page="15"
                    :footer-props="{
                      itemsPerPageText: 'Risultati per pagina',
                      itemsPerPageAllText: 'Tutti',
                      showFirstLastPage: true,
                    }"
                  >
                    >
                    <template v-slot:item.action="{ item }">
                      <v-btn large block :color="getColorSelected(item)" @click="caricaVisitaUti(item)" :disabled="item.disabled">
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.codiceUti ? item.codiceUti : item.merce }}
                        </span>
                      </v-btn>
                    </template>

                    <template v-slot:item.tipoUti="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.tipoUti ? item.tipoUti : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.fullEmpty="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.fullEmpty ? item.fullEmpty : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.operator="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.operator ? item.operator : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.carrierIn="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.carrierIn ? item.carrierIn : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.merce="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.merce ? item.merce : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.polizza="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.polizza ? item.polizza : "" }}
                        </span>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container style="background-color: #ffffff">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field v-model="searchUtiNonUscite" append-icon="mdi-magnify" label="Cerca" single-line hide-details filled dense style="max-width: 800px" />
                </v-col>
              </v-row>
            </v-container>

            <v-container style="background-color: #ffffff" v-if="visiteUtiNonUscite && visiteUtiNonUscite.length > 0">
              <v-row align="center">
                <v-col cols="12">
                  <v-data-table
                    :headers="visiteUtiHeaders"
                    :items="visiteUtiNonUscite"
                    :items-per-page="15"
                    :footer-props="{
                      itemsPerPageText: 'Risultati per pagina',
                      itemsPerPageAllText: 'Tutti',
                      showFirstLastPage: true,
                    }"
                  >
                    >
                    <template v-slot:item.action="{ item }">
                      <v-btn icon small :color="getColorSelected(item)" @click="caricaVisitaUti(item)" :disabled="item.disabled" title="Aggiungi Uti">
                        <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.codiceUti="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.codiceUti ? item.codiceUti : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.tipoUti="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.tipoUti ? item.tipoUti : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.fullEmpty="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.fullEmpty ? item.fullEmpty : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.operator="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.operator ? item.operator : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.carrierIn="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.carrierIn ? item.carrierIn : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.merce="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.merce ? item.merce : "" }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.polizza="{ item }">
                      <div>
                        <span class="text-h5 font-weight-bold">
                          {{ item && item.polizza ? item.polizza : "" }}
                        </span>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="showGoodVisitLoad" persistent width="600">
      <GoodVisitLoad :unit="item" v-on:ok="doProcessMerceVaria" v-on:cancel="showGoodVisitLoad = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import TripInfoPanelFull from "@/components/misc/TripInfoPanelFull";
import GoodVisitLoad from "@/components/rails/GoodVisitLoad";
import ToastMixin from "@/mixins/ToastMixin";
export default {
  name: "CaricoCarroConvoglio",
  components: { TripInfoPanelFull, GoodVisitLoad },
  mixins: [ToastMixin],
  props: {
    carroConvoglioId: {
      type: String,
    },
  },
  data() {
    return {
      searchPianificate: null,
      searchUtiNonUscite: null,
      tabs: null,
      infoPanel: [0],
      convoglio: {},
      carroConvoglio: {},
      targaCarro: null,
      viaggioOut: {},
      utiPianificate: [],
      utiNonUscite: [],
      visiteUtiPianificateSelectedId: [],
      visiteUtiNonUsciteSelectedId: [],
      showGoodVisitLoad: false,
      item: null,
      visiteUtiHeaders: [
        { text: "", value: "action", class: "header text-uppercase" },
        { text: "Codice Uti", value: "codiceUti", class: "header text-uppercase" },
        { text: "Tipo Uti", value: "tipoUti", class: "header text-uppercase" },
        { text: "Colli", value: "numeroColli", class: "header text-uppercase" },
        { text: "F/E", value: "fullEmpty", class: "header text-uppercase" },
        { text: "Operator", value: "operator", class: "header text-uppercase" },
        { text: "Carrier In", value: "carrierIn", class: "header text-uppercase" },
        { text: "Merce", value: "merce", class: "header text-uppercase" },
        { text: "Polizza", value: "polizza", class: "header text-uppercase" },
        { text: "Destinazione", value: "destinazione", class: "header text-uppercase" }
      ],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {
    visiteUtiPianificate() {
      if (this.searchPianificate && this.utiPianificate) {
        return this._.filter(
          this.utiPianificate,
          c =>
            (c && c.codiceUti && c.codiceUti.toLowerCase().includes(this.searchPianificate.toLowerCase())) ||
            (c && c.merce && c.merce.toLowerCase().includes(this.searchPianificate.toLowerCase())) ||
            (c && c.polizza && c.polizza.toLowerCase().includes(this.searchPianificate.toLowerCase()))
        );
      }
      return this.utiPianificate;
    },
    visiteUtiNonUscite() {
      if (this.searchUtiNonUscite && this.utiNonUscite) {
        return this._.filter(
          this.utiNonUscite,
          c =>
            (c && c.codiceUti && c.codiceUti.toLowerCase().includes(this.searchUtiNonUscite.toLowerCase())) ||
            (c && c.merce && c.merce.toLowerCase().includes(this.searchUtiNonUscite.toLowerCase())) ||
            (c && c.polizza && c.polizza.toLowerCase().includes(this.searchUtiNonUscite.toLowerCase()))
        );
      }

      return this.utiNonUscite;
    },
    disabledConferma() {
      if (this.visiteUtiPianificateSelectedId.length > 0 || this.visiteUtiNonUsciteSelectedId.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async fetchData() {
      const response = await this.$api.get(this.$apiConfiguration.BASE_PATH + "carriConvoglio/prepareCaricoCarro/" + this.carroConvoglioId);
      this.carroConvoglio = response.carroConvoglio;
      this.targaCarro = response.targaCarro;
      this.convoglio = response.convoglio;
      this.utiPianificate = response.utiPianificate;
      this.utiNonUscite = response.utiNonUscite;
    },
    
    getColorSelected(item) {
      return item.selected ? "blue lighten-2" : "white";
    },
    async caricaVisitaUti(item) {
      this.item = item;
      if (item.countType) {
        this.showGoodVisitLoad = true;
      } else {
        try {
          await this.$api.trainData.associaVisitaUtiToCarroConvoglio(this.carroConvoglioId, "IMPORT", item.id, null, null);
          this.goToLavorazioneConvoglioPartenza();
        } catch (e) {
          console.error(e);
          this.showError(e);
        }
      }
    },
    async doProcessMerceVaria() {
      const item = this.item;
      console.log("doProcessMerceVaria", item.colli, item.countType);
      try {
        await this.$api.trainData.associaVisitaUtiToCarroConvoglio(this.carroConvoglioId, "IMPORT", item.id, item.colli, item.isCompleted);
        this.goToLavorazioneConvoglioPartenza();
      } catch (e) {
        this.showError(e);
      }
    },

    goToLavorazioneConvoglioPartenza() {
      const path = "/callTreno/LavorazioneConvoglioPartenza/" + this.carroConvoglio.convoglioId;
      this.$router.push({ path: path });
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
